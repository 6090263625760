import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import MapMarker from '../components/MapMarker';

// examples:
import GoogleMap from '../components/GoogleMap';
import SearchBox from '../components/SearchBox';
import SEO from '../components/SEO';

const DealerMapStyled = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'map map map search'
    'map map map online'
    'map map map result'
    'map map map result'
    'map map map result';
  overflow-y: auto;
  .dealer-map {
    grid-area: map;
  }
  .dealer-search {
    background: var(--backgroundProduct);
    grid-area: search;
    align-self: center;
  }
  .online-store {
    grid-area: online;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    margin: 0.5rem 0;
    .button {
      font-size: 14px;
      max-height: 40px;
      border-radius: 20px;
      margin-left: auto;
      margin-right: 1rem;
    }
    @media (max-width: 1023px) {
      p {
        font-size: 12px;
      }
    }
  }
  .dealer-results {
    grid-area: result;
    overflow-y: auto;
    max-height: calc(80vh - 88px - 70px - 1rem);
    .dealer-count {
      padding: 0 2rem;
      font-size: 13px;
      text-align: center;
    }
  }
  .dealer-result-card {
    cursor: pointer;
    padding: 0.1rem 2rem;
    margin: 2rem;
    border-radius: 5px;
    border: 2px solid var(--backgroundProduct);
    background: var(--backgroundProduct);
    box-shadow: var(--boxShadow);
    transition: border 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    .dealer-type {
      color: var(--white);
      background: var(--borderColor);
      padding: 0 0.5rem;
      position: absolute;
      top: 2px;
      right: 2px;
      font-size: 12px;
      border-radius: 2px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin-top: -1rem;
    }
    li {
      display: inline-block;
      margin-right: 1rem;
      a {
        font-size: 14px;
        color: var(--textColor);
        text-decoration: none;
      }
    }
    li.dealer-phone-number {
      display: block;
      width: 100%;
    }
  }
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'search'
      'map'
      'online'
      'result';
  }
`;

const MAP_CENTER = { lat: 18.563147, lng: 81.639645 };
const BOUNDS = {
  nw: {
    lat: 34.93055572709197,
    lng: 19.36686209999999,
  },
  se: {
    lat: -73.10914192741043,
    lng: 283.03873709999993,
  },
  sw: {
    lat: -73.10914192741043,
    lng: 19.36686209999999,
  },
  ne: {
    lat: 34.93055572709197,
    lng: 283.03873709999993,
  },
};

class Searchbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      handleApiLoaded: null,
      places: [],
      inBounds: [],
      dealers: props.data.dealers.nodes,
    };
  }

  apiHasLoaded = (map, maps, dealers, bounds) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
      inBounds: dealers.filter(
        (dealer) =>
          dealer.location.lat < bounds.ne.lat &&
          dealer.location.lat > bounds.se.lat &&
          dealer.location.lng > bounds.nw.lng &&
          dealer.location.lng < bounds.se.lng
      ),
    });
  };

  boundsUpdated = (bounds, dealers) => {
    this.setState({
      inBounds: dealers.filter(
        (dealer) =>
          dealer.location.lat < bounds.ne.lat &&
          dealer.location.lat > bounds.se.lat &&
          dealer.location.lng > bounds.nw.lng &&
          dealer.location.lng < bounds.se.lng
      ),
    });
  };

  addPlace = (place) => {
    this.setState({ places: place });
  };

  markerZoom = (e) => {
    const marker = document.querySelector(
      `[data-dealer-marker-id="${e.currentTarget.dataset.dealerId}"]`
    );
    marker.style.transform = 'scale(2)';
  };

  markerShrink = (e) => {
    const marker = document.querySelector(
      `[data-dealer-marker-id="${e.currentTarget.dataset.dealerId}"]`
    );
    marker.style.transform = 'scale(1)';
  };

  render() {
    const { places, dealers, mapApiLoaded, mapInstance, mapApi, inBounds } =
      this.state;
    return (
      <>
        <SEO title="Find a Bont Rowing Dealer" />
        <DealerMapStyled>
          <GoogleMap
            className="dealer-map"
            defaultZoom={3}
            defaultCenter={MAP_CENTER}
            bootstrapURLKeys={{
              key: 'AIzaSyCRoPiw5B0bRKlQIV-l3jzJ0lc_ZV-oYBw',
              libraries: ['places', 'geometry'],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) =>
              this.apiHasLoaded(map, maps, dealers, BOUNDS)
            }
            onChange={({ bounds }) => this.boundsUpdated(bounds, dealers)}
            options={{
              styles: [
                {
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#212121',
                    },
                  ],
                },
                {
                  elementType: 'labels.icon',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#757575',
                    },
                  ],
                },
                {
                  elementType: 'labels.text.stroke',
                  stylers: [
                    {
                      color: '#212121',
                    },
                  ],
                },
                {
                  featureType: 'administrative',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#757575',
                    },
                  ],
                },
                {
                  featureType: 'administrative.country',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#9e9e9e',
                    },
                  ],
                },
                {
                  featureType: 'administrative.land_parcel',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  featureType: 'administrative.locality',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#bdbdbd',
                    },
                  ],
                },
                {
                  featureType: 'poi',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#757575',
                    },
                  ],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#181818',
                    },
                  ],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#616161',
                    },
                  ],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'labels.text.stroke',
                  stylers: [
                    {
                      color: '#1b1b1b',
                    },
                  ],
                },
                {
                  featureType: 'road',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      color: '#2c2c2c',
                    },
                  ],
                },
                {
                  featureType: 'road',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#8a8a8a',
                    },
                  ],
                },
                {
                  featureType: 'road.arterial',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#373737',
                    },
                  ],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#3c3c3c',
                    },
                  ],
                },
                {
                  featureType: 'road.highway.controlled_access',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#4e4e4e',
                    },
                  ],
                },
                {
                  featureType: 'road.local',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#616161',
                    },
                  ],
                },
                {
                  featureType: 'transit',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#757575',
                    },
                  ],
                },
                {
                  featureType: 'water',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#000000',
                    },
                  ],
                },
                {
                  featureType: 'water',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#3d3d3d',
                    },
                  ],
                },
              ],
            }}
          >
            {dealers.map((dealer) => (
              <MapMarker
                details={dealer}
                lat={dealer.location.lat}
                lng={dealer.location.lng}
              />
            ))}
          </GoogleMap>
          {mapApiLoaded && (
            <div className="dealer-search">
              <SearchBox
                map={mapInstance}
                mapApi={mapApi}
                addplace={this.addPlace}
              />
            </div>
          )}
          <div className="online-store">
            <p>
              <strong>Bont Rowing Online Store</strong>
            </p>
            <a href="https://shop.bontrowing.com" className="button">
              Shop online
            </a>
          </div>
          <div className="dealer-results">
            {inBounds.length > 0 && (
              <p className="dealer-count">
                We've found {inBounds.length} Bont Rowing retailers in your
                search area:
              </p>
            )}
            {inBounds.map((dealer) => (
              <div
                className="dealer-result-card"
                data-dealer-id={dealer.id}
                onMouseEnter={this.markerZoom}
                onMouseLeave={this.markerShrink}
              >
                <span className="dealer-type">
                  {dealer.type ? dealer.type.toUpperCase() : 'DEALER'}
                </span>
                <p>
                  <strong>{dealer.name}</strong>
                </p>
                <p>
                  <ul>
                    {dealer.phone && (
                      <li className="dealer-phone-number">
                        <a
                          href={`tel:${dealer.phone}`}
                          title={dealer.name}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {dealer.phone}
                        </a>
                      </li>
                    )}
                    {dealer.website && (
                      <li>
                        <a
                          href={dealer.website}
                          title={dealer.name}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Website
                        </a>
                      </li>
                    )}
                    {dealer.email && (
                      <li>
                        <a
                          href={`mailto:${dealer.email}`}
                          title={dealer.name}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Email
                        </a>
                      </li>
                    )}
                    {dealer.location.lat && (
                      <li>
                        <a
                          href={
                            dealer.googleMap
                              ? dealer.googleMap
                              : `https://maps.google.com/?q=${dealer.location.lat},${dealer.location.lng}`
                          }
                          title={dealer.name}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Location
                        </a>
                      </li>
                    )}
                  </ul>
                </p>
              </div>
            ))}
          </div>
        </DealerMapStyled>
      </>
    );
  }
}

export const query = graphql`
  query {
    dealers: allSanityDealer {
      nodes {
        name
        website
        type
        phone
        googleMap
        location {
          lat
          lng
        }
        email
        id
      }
    }
  }
`;

export default Searchbox;
